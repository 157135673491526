
import React, {useState} from "react"

import Layout from "../components/layout"
import WebappsMenupageComponent from "../components/webapps/components/menupage";

import authmenulist from "../content/authmenulist.json";


const HomePage = ({location}) => {
	const [token, setToken] = useState("");
	const [userinfo, setUserinfo] = useState({});


	const menusrc = userinfo.hasOwnProperty("accessid")?(authmenulist["access"+userinfo.accessid]?authmenulist["access"+userinfo.accessid]:[]):[];

	return <Layout fullPath={location.pathname}
			newUserHandler={(newtoken, newuserinfo)=>{setToken(newtoken); setUserinfo(newuserinfo)}}
			privatePage={true} usegatedcontent={true}>
			{(menusrc.length > 0 && token.length > 0) ? <>
				<br/>
				<h1 className={"font-size-medium text-centered"}>{"Hi "+userinfo.name+"!"}</h1>
				<WebappsMenupageComponent
						menusrc={menusrc}
						title={""}
						urlprefix={location.pathname}
					/>
			</>
			:<p class="underconstruction font-size-large">
				{token.length > 0?"No items found":"Loading..."}
			</p>}
		</Layout>
}

export default HomePage;
